import CryptoJS from 'crypto-js'
import { authSlice, loginSuccess } from '../slices/authSlice'
import { type RootState, type AppDispatch } from '../store'
import { getErrorss } from '../../functions/errors/GetErrors'

import { type ApiResponse, type IVerifyPhoneJSON, type IVerifyPhoneFormValues, type ResponseObject } from '../../types/userTypes/TAuth'
import { urlAUTH, urlAPI, ACCESS_KEY, USER_ID_KEY } from '../../urls'
import { setResendInProcess } from '../slices/modalSlice'

export function generateHash (): string | null {
    try {
        const now = new Date(Date.now())
        const hash = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(now.toUTCString()), now.toDateString()).toString()
        return hash
    } catch {
        return null
    }
}

export const login = (formValues: IVerifyPhoneFormValues) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.authFetching(true))
        const sendObject = {
            ...formValues, renew: false
        }
    
        const response = await fetch(`${urlAUTH}/login`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const dataJSON = await response.json() as ApiResponse

        if (dataJSON.success) {
            if (dataJSON.response.error != null) {
                dispatch(authSlice.actions.loginFailed(dataJSON))
                return { ...dataJSON, textNavigate: '/auth/login', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            } else {
                dispatch(loginSuccess({
                    jwt: dataJSON.response.jwt,
                    id: dataJSON.response.id,
                    category: dataJSON.response?.category
                }))

                let redirectModule = ''
                if (dataJSON?.response?.category === 'Agent') {
                    redirectModule = '/agent'
                } else if (dataJSON?.response?.category === 'Landlord') {
                    redirectModule = '/landlord/dashboard'
                }
                return { ...dataJSON, textNavigate: redirectModule, isSuccessful: true, textNotification: '' }
            }
        } else {
            dispatch(authSlice.actions.loginFailed(dataJSON))
            return { textNavigate: '/auth/login', isSuccessful: false, textNotification: 'Some error occurred,please try again' }
        }
    } catch (error) {
        console.log(error)
    }
}

export const verifyPhoneCreate = (formValues: IVerifyPhoneFormValues) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.authFetching(true))
        const sendObject = {
            ...formValues, renew: false
        }

        const response = await fetch(`${urlAUTH}/sendMobileOTP`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const dataJSON: IVerifyPhoneJSON = await response.json()
      
        if (dataJSON.response?.sent) {
            dispatch(authSlice.actions.numberSuccess(formValues.phone))
            return { ...dataJSON, textNavigate: '/auth/phone_verify', isSuccessful: true, textNotification: '' }
        }
        return { ...dataJSON, textNavigate: '/auth/register1', isSuccessful: false, textNotification: getErrorss(dataJSON) }
    } catch (error) {
        console.log(error)
    }
}

// step 2 register
export const verifyPhoneConfirm = (formValues: IVerifyPhoneFormValues) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(authSlice.actions.authFetching(true))
        const number = getState().auth.userNUMBER
        // console.log(number, 'number')
        // console.log(formValues, 'formValues')
        const sendObject = {
            ...formValues, renew: false, phone: number
        }
        //    console.log(sendObject, 'sendObject')
        const response = await fetch(`${urlAUTH}/checkMobileOTP`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const dataJSON = await response.json() as ResponseObject
        //     console.log(dataJSON, 'dataJSON2<<<<<<<<<<<<<<<<?????COONNNFFFIIIRRRMMMM')
        if (dataJSON.success && dataJSON.response?.approved) {
            dispatch(authSlice.actions.authFetching(false))
            return { ...dataJSON, textNavigate: '/auth/register2', isSuccessful: true, textNotification: '' }
            // textNavigate: '/auth/register2',
        } else {
            return { ...dataJSON, textNavigate: '/auth/phone_verify', isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (error) {
        console.log(error)
        //  dispatch(authSlice.actions.registerFailed(error))
    }
}

/// barer

export const confirmResetPasswordPhone = (formValues: IVerifyPhoneFormValues) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(authSlice.actions.authFetching(true))
        const number = getState().auth.userNUMBER

        const sendObject = {
            ...formValues, phone: number, changePass: true // renew: true,
        }

        const response = await fetch(`${urlAUTH}/checkMobileOTP`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const dataJSON = await response.json() as ResponseObject
        if (dataJSON.success && dataJSON.response?.approved) {
            dispatch(authSlice.actions.aes_codeSuccess(dataJSON.response?.code))
            return { ...dataJSON, textNavigate: '/auth/reset_password_final', isSuccessful: true, textNotification: '' }
        } else {
            return { ...dataJSON, textNavigate: '/auth/password_verify', isSuccessful: false, textNotification: 'Uncorrect code' }
        }
    } catch (error) {
        console.log(error)
    }
}

export const confirmResetPasswordEmail = (formValues?: IVerifyPhoneFormValues) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(authSlice.actions.authFetching(true))
        const email = getState().auth.userEMAIL

        const sendObject = {
            ...formValues, email, changePass: true // renew: true,
        }
        //  console.log(sendObject, 'sendObject')

        // sendMailOTP
        const response = await fetch(`${urlAUTH}/checkMailOTP`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const dataJSON = await response.json() as ResponseObject
        //  console.log(dataJSON, ' dataJSON      confirmResetPasswordEmail')
        if (dataJSON.success && dataJSON.response?.approved) {
            dispatch(authSlice.actions.aes_codeSuccess(dataJSON.response?.code))
            return { ...dataJSON, textNavigate: '/auth/reset_password_final_email', isSuccessful: true, textNotification: '' }
        } else {
            return { ...dataJSON, textNavigate: '/auth/password_verify_email', isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (error) {
        console.log(error)
    }
}

export const resetPasswordPhone = (formValues: IVerifyPhoneFormValues) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(authSlice.actions.authFetching(true))
        const number = getState().auth.userNUMBER
        const AES_CODE = getState().auth.aes_code
        const sendObject = {
            password: formValues.password, code: AES_CODE, phone: number
        }

        const response = await fetch(`${urlAUTH}/renewPasswordWithMobile`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const dataJSON = await response.json() as ResponseObject
        if (dataJSON.success && dataJSON.response?.approved) {
            dispatch(authSlice.actions.authFetching(false))
            return { ...dataJSON, textNavigate: '/auth/login', isSuccessful: true, textNotification: 'Your password has been changed' }
        } else { // getErrors
            return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (error) {
        console.log(error)
    }
}

export const resetPasswordEmail = (formValues: IVerifyPhoneFormValues) => async (dispatch: AppDispatch, getState: () => RootState) => {
    // console.log(formValues, 'formValues resetPasswordEmail')
    try {
        dispatch(authSlice.actions.authFetching(true))
        const email = getState().auth.userEMAIL
        const AES_CODE = getState().auth.aes_code
        const sendObject = {
            password: formValues.password, code: AES_CODE, email
        }
        const response = await fetch(`${urlAUTH}/renewPasswordWithMail`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const dataJSON = await response.json() as ResponseObject
        //  console.log(dataJSON, 'dataJSON')
        if (dataJSON.success && dataJSON.response?.approved) {
            dispatch(authSlice.actions.authFetching(false))
            return { ...dataJSON, textNavigate: '/auth/login', isSuccessful: true, textNotification: 'Your password has been changed' }
        } else {
            return { ...dataJSON, textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
        }
    } catch (error) {
        console.log(error)
    }
}

// reset password actions  на телефон при регистрациии renew:false

export const verifyResetPasswordPhone = (formValues?: IVerifyPhoneFormValues) => async (dispatch: AppDispatch, getState: () => RootState) => {
    //  console.log(formValues, 'formValues________-???????')

    const phone = formValues?.phone ?? getState().auth.userNUMBER
    // const renew = (formValues != null && formValues !== undefined) && formValues?.renew !== false
    //  console.log(phone, 'verifyResetPasswordPhone')

    const isRenew = formValues?.hasOwnProperty('renew')
    const renew = formValues?.renew
    // console.log({ isRenew, renew })
    try {
        dispatch(authSlice.actions.authFetching(true))

        const sendObject = { phone, ...isRenew && renew != null ? { renew } : { changePass: true } }
        //  console.log(sendObject)
        const response = await fetch(`${urlAUTH}/sendMobileOTP`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const dataJSON: IVerifyPhoneJSON = await response.json()
        //    console.log(dataJSON, 'dataJSON')
        if (dataJSON.response?.sent) {
            //   console.log(dataJSON.response?.sent, 'dataJSON.response?.sent')
            if (formValues?.phone) {
                dispatch(authSlice.actions.numberSuccess(formValues?.phone))
            } else {
                dispatch(authSlice.actions.numberSuccess(phone))
            }

            dispatch(setResendInProcess(false))
            return { ...dataJSON, textNavigate: '/auth/password_verify', isSuccessful: true, textNotification: isRenew && renew == false ? 'The OTP was successfully re-sent' : '' }
        }
        // getErrors
        // console.log(dataJSON,"dataJSON")
        dispatch(authSlice.actions.authFetching(false))
        return { ...dataJSON, textNavigate: '/auth/reset_password_way_phone', isSuccessful: false, textNotification: getErrorss(dataJSON) }
    } catch (error) {
        console.log(error)
    }
}

export const verifyResetPasswordEmail = (formValues?: IVerifyPhoneFormValues) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const email = formValues?.email ?? getState().auth.userEMAIL
    // ?? window.localStorage.getItem(USER_EMAIL_KEY)
    // console.log(email, 'verifyResetPasswordEmail')

    const isRenew = formValues?.hasOwnProperty('renew')
    const renew = formValues?.renew
    // console.log({ isRenew, renew })
    try { // password_verify_email
        dispatch(authSlice.actions.authFetching(true))
        //  console.log(formValues, 'formValues')
        const sendObject = { email, ...isRenew && renew != null ? { renew } : { changePass: true } }
        // console.log(sendObject, 'sendObject')
        // checkMailOTP
        const response = await fetch(`${urlAUTH}/sendMailOTP`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const dataJSON: IVerifyPhoneJSON = await response.json()
        // console.log(dataJSON, 'dataJSON verifyResetPasswordEmail')
        if (dataJSON.response?.sent) {
            if (formValues?.email) {
                dispatch(authSlice.actions.emailSuccess(formValues?.email))
            } else {
                dispatch(authSlice.actions.emailSuccess(email))
            }
            dispatch(setResendInProcess(false))
            return { ...dataJSON, textNavigate: '/auth/password_verify_email', isSuccessful: true, textNotification: '' }
        }
        dispatch(authSlice.actions.authFetching(false))
        return { ...dataJSON, textNavigate: '/auth/reset_password_way_email', isSuccessful: false, textNotification: getErrorss(dataJSON) }
    } catch (error) {
        console.log(error)
    }
}

export const clearDeviceToken = () => async (dispatch: AppDispatch) => {
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    const userID = window.localStorage.getItem(USER_ID_KEY) ?? ''
    try {
        const sendObject = {
            method: 'PUT',
            collection: 'users',
            id: Number(userID),
            body: {
                DeviceToken: null
            }
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(sendObject),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const responseJSON = await response.json() as unknown as ApiResponse

        return responseJSON
    } catch (error) {
        console.log(error)
    }
}
