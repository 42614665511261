import { type AppDispatch, type RootState } from '../../../../store'
import { dataFetchingFinish, dataFetchingStart } from '../../../../slices/modalSlice'
import { landlordAddUnitReadFromStorage } from '../utils/landlordAddUnitHandleStorage'
import { createSingleLandlord } from '../../../unitsActions'
import nanToNull from '../../../../../functions/adapters/universal/nanToNull'
import { type NavigateFunction } from 'react-router-dom'

export const correctLandlordInfo = (unitID?: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    dispatch(dataFetchingStart())
    try {
        const storedData = landlordAddUnitReadFromStorage()
        const landlordID = storedData.owners_passport?.landlordID
        const result = await dispatch(createSingleLandlord(data, landlordID))
        if (result != null) {
            dispatch(dataFetchingFinish())
            return {
                textNotification: '',
                textNavigate: `/landlord/modal/landlord_create_unit_poa/${nanToNull(unitID) ?? '-'}/-`,
                isSuccessful: true
            }
        }
    } catch (err) {
        console.log('Error while correcting landlord info', err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false
    }
}

export const goBackCorrectLandlordInfo = (navigate: NavigateFunction, unitID?: number) => () => {
    navigate(`/landlord/modal/landlord_create_unit_owners_passport/${nanToNull(unitID) ?? '-'}/-`)
}
