/**
 * Chats
 */

export const MESSAGE_UNPUBLISH_TIME = 1000 * 60 * 60 * 24

/**
 * Notifies
 */

export const NOTIFY_DELAY = 1000 * 5

export const UNIT_SOLD_NOTIFY_DELAY = 1000 * 60 * 60 * 24

/**
 * Hide Emails
 */

export const hideMails = [
    'erter.live@gmail.com', 'projects@bso.ae', 'it@bso.ae', 'it2@bso.ae', 'amemetoff@yandex.ru', 'it3@bso.ae', 'magic.soliman@yahoo.com', 'arsenij.ermolaev2003@gmail.com', 'arsenij.ermolaev2003.1@gmail.com', 'officemac1993@gmail.com', 'jamal_ayash@hotmail.com'
]

/**
 * REGULAR EXPRESSIONS
 */

export const regExps = {
    isYear: '^\\d{4}$',
    forBedrooms: '^\\d+$|^[Ss]tudio$',
    isNumber: '^[0-9]+$',
    isEmiratesIDNumber: '^784-?[0-9]{4}-?[0-9]{7}-?[0-9]{1}$',
    isEmail: '^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$',
    isPhone: '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$',
    isGoogleMapsLink: '^https:\\/\\/(?=.*(goo\\.gl|google\\.com))(?=.*maps)'// FOR URLS LIKE https://www.google.com/maps/place/Tbilisi+Zoo/@41.70939,44.7642921,13.97z/data=!4m6!3m5!1s0x4044732b601ac833:0xff4b936dbf9dcb49!8m2!3d41.7136439!4d44.7803658!16s%2Fg%2F12105n99?entry=ttu&g_ep=EgoyMDI0MDgyNy4wIKXMDSoASAFQAw%3D%3D OR https://maps.app.goo.gl/2CmWnXzf3TePHfW58
}

/**
 * ADMIN VIEW STATUSES
 */

export const offerAdminViewStatuses = ['Confirmed', 'Completed', 'Canceled']

export const days = ['Sun', 'Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat']

/**
 * CHAT CONSTANTS
 */

export const LANDLORD_OFFER_CHAT_VISIBLE_DURATION = 1
