import React from 'react'
import _ from 'lodash'
import { nanoid } from 'nanoid'
import LandlordHeader from '../LandlordHeader'
import { Link, useParams } from 'react-router-dom'

const LandlordServices = (): JSX.Element => {
    const { unitID } = useParams()
    const linksCol = [
        [
            {
                id: 'finding_tenant',
                // link: `/landlord/modal/landlord_create_unit_marketing/${String(unitID)}/-`,
                title: 'Finding a tenant',
                description: ['Due diligence of BSO', 'reduces the risk of dealing with high-risk profile tenants'],
                img: '/services/get_services_2.png',
                class: 'col-8',
                type: 'post'
            },
            {
                id: 'finding_buyer',
                title: 'Finding a buyer',
                description: [],
                img: '/services/get_services_13.png',
                class: 'col-4',
                type: 'post'
            }
        ],
        [
            {
                id: 'power_attorney',
                title: 'Power of attorney',
                description: [],
                img: '/services/get_services_14.png',
                class: 'col-4',
                type: 'post'
            },
            {
                id: 'legal_notice',
                title: 'Legal notice',
                description: [],
                img: '/services/get_services_5.png',
                class: 'col-4',
                type: 'post'
            },
            {
                id: 'property_maintenance',
                title: 'Property maintenance',
                description: [],
                img: '/services/get_services_8.png',
                class: 'col-4',
                type: 'post'
            }
        ],
        [
            {
                id: 'tenant_screening',
                title: 'Tenant screening',
                description: [],
                img: '/services/get_services_7.png',
                class: 'col-4',
                type: 'post'
            },
            {
                id: 'rental_dispute',
                title: 'Rental dispute',
                description: ['Due diligence of BSO', 'reduces the risk of dealing with high-risk profile tenants'],
                img: '/services/get_services_4.png',
                class: 'col-8',
                type: 'post'
            }
        ]
    ]
    return (
        <div data-id="services_page" className="page d-flex flex-column flex-row-fluid fs-5">
            <LandlordHeader active={'Order a service'}/>
            <div className={'container py-4 px-4 mt-4'}>
                {
                    _.map(linksCol, (row) => (
                        <div className={'row mb-4'} key={nanoid()}>
                            {
                                _.map(row, (item) => (
                                    <div className={`${item.class} px-2`} key={nanoid()}>
                                        <Link to={(item as any).link ?? `/landlord/modal/${item.id}/${String(unitID)}/-`} className="col-lg-3 col-md-6 col-sm-6 text-center" style={{ height: 'fit-content' }}>
                                            <div
                                                className="anime-card rounded text-center m-0 h-100px overflow-hidden bgi-no-repeat bgi-size-cover bgi-position-x-end bgi-position-y-center shadow-sm"
                                                // style={{ backgroundImage : `url(/assets/sharedMedia${link.img})`}}
                                            >
                                                <div className="d-flex flex-wrap flex-fill g-0 p-4 m-0 h-80px">
                                                    <div className="d-flex flex-column w-100">
                                                        <div className="fw-bold fs-7 text-dark text-start">{item.title}</div>
                                                        { item.description.length > 0
                                                            ? <div className="d-flex flex-wrap align-items-start fs-8">
                                                                <div className="text-start me-1 p-0 mb-0 disable"
                                                                    style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', color: '#7E8299' }}>
                                                                    <span>{item.description[0]}</span>
                                                                </div>
                                                                <div className="text-start me-1 p-0 mb-0 disable"
                                                                    style={{ /* whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', */ color: '#7E8299' }}>
                                                                    <span>{item.description[1]}</span>
                                                                </div>
                                                            </div>
                                                            : <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
            <div className={'h-75px'}/>
        </div>
    )
}

export default LandlordServices
