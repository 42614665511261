import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router'
import { addTokenDevice, fetchSocketUser, fetchUser, fetchUsers, updateUserTokenDevice } from '../../store/actionsCreators/userActions'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { fetchProperties } from '../../store/actionsCreators/propertyActions'
import { fetchDocuments } from '../../store/actionsCreators/documentActions'
import SearchProvider from '../../app/SearchProvider'
import useOrientation from '../../hooks/UseOrientation'
import LandscapePlaceholder from '../shared/landscape-placeholder'
import { getChatList } from '../../store/actionsCreators/chatListActions'
import { fetchViewings } from '../../store/actionsCreators/viewingsActions'
import { fetchSingleUnit, fetchUnits } from '../../store/actionsCreators/unitsActions'
import { fetchOffers, processSocketOffer } from '../../store/actionsCreators/offerActions'
import { fetchAppConfig } from '../../store/actionsCreators/appActions'
import { fetchAdvertises } from '../../store/actionsCreators/advertiseActions/fetchAdvertiseAction'
import Loader from '../../app/custom/loaders/Loader'
import asyncQueue from '../../functions/utils/asyncQueue'

import { ACCESS_KEY, USER_ID_BACK_ID_LOAD, USER_ID_KEY } from '../../urls'
import _ from 'lodash'
import { landlordSocket } from './landlordSocket'
import { type AppDispatch , type RootState, store } from '../../store/store'
import { type NavigateFunction } from 'react-router-dom'
import { addViewing, setViewings } from '../../store/slices/viewingsSlice'
import { reduceViewing, reduceViewingAfterCreate } from '../../functions/agent/socket/reduceViewing'
import { reduceViewingAfterUpdateKey } from '../../functions/agent/socket/reduceViewingAfterUpdateKey'
import { type ISettings, setCurrentUserUnits, type User, userUpdate } from '../../store/slices/userSlice'
import { setIsUserVerified } from '../../store/slices/modalSlice'
import { setStatuses } from '../../store/slices/agentSlice'
import getAgentStatuses from '../../functions/agent/documents/checkDocumentsForAproved'
import { swalSuccess } from '../../functions/swalOptions'
import { getCorrectName } from '../../functions/agent/agentModuleComponent/getCorrectName'
import { documentAddUpdate } from '../../store/slices/documentSlice'
import { addAdvertise, setAdvertises } from '../../store/slices/advertisesSlice'
import { reduceAdvertice } from '../../functions/agent/socket/reduceAdvertice'
import AlertsComponentVerify from '../../AlertsComponentVerify'
import { getLatestServiceAgentSubscription } from '../../functions/agent/subscriptions/getLatestServiceAgentSubscription'
import awaitAction from '../../functions/utils/awaitAction'
import { showNegotiateSwals } from '../../functions/agent/offers/showNegotiateSwals'
import { getChat } from '../../store/actionsCreators/chatActions'
import customLog from '../../functions/log'
import {
    checkStoppedCreateUnitPipeline
} from '../../store/actionsCreators/unitActions/landlordUnitsActions/utils/checkStoppedCreateUnitPipeline'
import { getAllTasks } from '../../store/actionsCreators/taskActions/getAllTasks'
import {
    checkVerifiedUnits
} from '../../store/actionsCreators/unitActions/landlordUnitsActions/utils/checkVerifiedUnits'
import {
    processOfferSwalForLandlord,
    showNegotiateSwalsToLandlord
} from '../../functions/landlord/offers/showNegotiateSwals'
import { removeDataAttributes } from '../../functions/adapters/universal/removeDataAttributes'
import useLandlordLoadProcessData from "./useLandlordLoadProcessData";

export default function LandlordModule (): JSX.Element {
    const dispatch = useAppDispatch()
    const isUserVerified = useAppSelector((state: RootState) => state.modal.isUserVerified)
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const [dataLoaded, setDataLoaded] = useState(false)
    const { orientation } = useOrientation()

    const navigate = useNavigate()
    const { documentsLoaded, statusesApproved, documentsRejected, documentsInProgress } = useAppSelector((state: RootState) => state.agentStates)

    //  const [dataLoaded, setDataLoaded] = useState(false)
    let userID = window.localStorage.getItem(USER_ID_KEY)
    let webSocket = {} as any
    const [wb, setWb] = useState<any>({})

    useEffect(() => {
        const firebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
        }

        userID = window.localStorage.getItem(USER_ID_KEY)
        const initializeWebSocket = (dispatch: AppDispatch, navigate: NavigateFunction): void => {
            setDataLoaded(true)
            webSocket = new WebSocket('wss://bso.ae/ws')
            setWb(webSocket)
            void landlordSocket(webSocket, dispatch)
        }

        const checkToken = async (dispatch: AppDispatch, navigate: NavigateFunction): Promise<void> => {
            const maxAttempts = 16
            let attemptCount = 0
            let currentToken: string | null = null

            while (attemptCount < maxAttempts && !currentToken) {
                try {

                    currentToken = (window as unknown as Window & { devicetoken: string }).devicetoken || window.localStorage.getItem('devicetoken')
                    if (currentToken) {
                        void dispatch(updateUserTokenDevice(currentToken))
                        initializeWebSocket(dispatch, navigate)
                        break
                    } else {
                        attemptCount++
                        await new Promise(resolve => setTimeout(resolve, 500))
                    }
                } catch (error) {
                    console.error('Error updating token', error)
                    setDataLoaded(true)
                    break
                }
            }

            if (!currentToken) {
                void dispatch(updateUserTokenDevice(`BrowserTokenUnfound${userID}`))
                initializeWebSocket(dispatch, navigate)
            }
        }

        void (async () => {
            const jwt = window.localStorage.getItem(ACCESS_KEY)
            userID = window.localStorage.getItem(USER_ID_KEY)
            window.myController = new AbortController()
            if (jwt && userID) {
                const results = await asyncQueue([
                    dispatch(fetchUser()),
                    dispatch(fetchViewings()),
                    dispatch(fetchOffers(false, true)),
                    dispatch(fetchUnits()),
                    dispatch(fetchDocuments()),
                    dispatch(fetchUsers()),
                    dispatch(fetchProperties()),
                    dispatch(getChatList()),
                    dispatch(fetchAdvertises()),
                    dispatch(fetchAppConfig()),
                    dispatch(getAllTasks())
                ])

                const isLoad = results.every((el: any) => {
                    return el?.isSuccessful === true || el?.success === true
                })

                if (isLoad) {
                    const userAgent = navigator.userAgent
                    const isIPhone = _.includes(userAgent.toLowerCase(), 'iphone')
                    const isAndroid = _.includes(userAgent.toLowerCase(), 'android')

                    if (isAndroid) {
                        try {
                            const { initializeApp } = await import('firebase/app')
                            const firebaseApp = initializeApp(firebaseConfig)
                            const { getMessaging, getToken, onMessage } = await import('firebase/messaging')
                            const messaging = getMessaging(firebaseApp)

                            if (Notification.permission === 'default') {
                                const permission = await Notification.requestPermission()
                                if (permission === 'granted') {
                                    const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
                                    if (currentToken != null) {
                                        console.log('FB Token:', currentToken.slice(0, 5) + '...')
                                        await dispatch(updateUserTokenDevice(currentToken))
                                        initializeWebSocket(dispatch, navigate)
                                    } else {
                                        await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                        initializeWebSocket(dispatch, navigate)
                                    }
                                } else if (permission === 'denied') {
                                    await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                    initializeWebSocket(dispatch, navigate)
                                    alert('Notifications have been blocked by the user.')
                                }
                            } else if (Notification.permission === 'granted') {
                                const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
                                if (currentToken != null) {
                                    console.log('FB Token:', currentToken.slice(0, 5) + '...')
                                    await dispatch(updateUserTokenDevice(currentToken))
                                    initializeWebSocket(dispatch, navigate)
                                } else {
                                    await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                    initializeWebSocket(dispatch, navigate)
                                }
                            } else if (Notification.permission === 'denied') {
                                await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                                initializeWebSocket(dispatch, navigate)
                                alert('Notifications have been blocked by the user.')
                            }

                            onMessage(messaging, (payload) => {
                                navigator.serviceWorker.ready.then((registration) => {
                                    registration.showNotification(payload.notification?.title as string, {
                                        body: payload.notification?.body
                                    })
                                }).catch((e) => { console.log(e) })
                            })
                        } catch (err) {
                            console.log(err)
                        }
                    } else if (isIPhone) {
                        await checkToken(dispatch, navigate)
                    } else {
                        setDataLoaded(true)
                        await dispatch(updateUserTokenDevice(`BrowserToken${userID}`))
                        initializeWebSocket(dispatch, navigate)
                    }
                } else {
                    setDataLoaded(false)
                    navigate('/auth/login')
                }
            }
        })()
        const body = document.querySelector('body')
        if (body != null) {
            body.setAttribute('style', 'user-select: text !important;')
        }
    }, [])

    // useEffect(() => {
    //     if (dataLoaded) {
    //         // логика по загрузке нужных роутов в лэндлорде аналог  AgentLoadProcessData
    //     }
    // }, [dataLoaded])

    useLandlordLoadProcessData(dataLoaded)

    // useEffect(() => {
    //     const processUserData = async (): Promise<void> => {
    //         if (dataLoaded) {
    //             const verifiedStatus = statusesApproved.emiratesApproved || statusesApproved.pasportApproved
    //             const loadSts = documentsLoaded.emiratesLoaded || documentsLoaded.pasportLoaded
    //             const rejSts = documentsRejected.passportReject || documentsRejected.emiratesReject
    //             const verifiedInProgress = documentsInProgress.emiratesInProgress || documentsInProgress.pasportInProgress
    //             let redirectModule = '/landlord/modal/update_emirates_front_together/INagentMODULE/first'
    //             const notifylink = (window as unknown as Window & { notifylink: string }).notifylink
    //
    //             if (notifylink) {
    //                 redirectModule = notifylink
    //             } else if (currentUser?.tutorialCompleted) {
    //                 if (!currentUser?.emailVerified) {
    //                     redirectModule = '/landlord/my_units'
    //                 } else {
    //                     redirectModule = '/landlord/my_units'
    //                     // if (verifiedStatus) { // || loadSts || verifiedInProgress
    //                     //     // if (currentUser?.Orders && Array.isArray(currentUser.Orders) && currentUser.Orders.length > 0) {
    //                     //     //     if ((currentUser.Settings as ISettings[])?.[0]?.Areas?.length > 0) {
    //                     //     //         if (location.pathname.includes('agent_settings_for_subscriptions') && status === 'true') {
    //                     //     //             redirectModule = location.pathname
    //                     //     //         } else if (location.pathname.includes('/agent/modal/agent_advertise_create_duration/')) {
    //                     //     //             redirectModule = location.pathname
    //                     //     //         } else if (location.pathname.includes('/agent/chats/')) {
    //                     //     //             redirectModule = location.pathname
    //                     //     //         } else if (location.pathname.includes('/agent/modalView/viewing_modal/')) {
    //                     //     //             redirectModule = location.pathname
    //                     //     //         } else if (location.pathname.includes('/agent/modal/agent_settings_for_areas_extension/')) {
    //                     //     //             redirectModule = location.pathname
    //                     //     //         } else if (window.localStorage.getItem(USER_ID_BACK_ID_LOAD) === 'true') {
    //                     //     //             redirectModule = '/agent/modal/update_emirates_back/not/not'
    //                     //     //         } else {
    //                     //     //             redirectModule = '/agent/units/nav'
    //                     //     //         }
    //                     //     //         //  redirectModule = '/agent/units/nav'
    //                     //     //     } else {
    //                     //     //         if (currentUser?.Orders && Array.isArray(currentUser.Orders)) {
    //                     //     //             const latestServiceAgentSubscription = getLatestServiceAgentSubscription(currentUser?.Orders)
    //                     //     //             const service = latestServiceAgentSubscription?.Service?.[0]
    //                     //     //             if (service?.Package?.includes('Standard')) {
    //                     //     //                 redirectModule = `/agent/modal/agent_settings_for_subscriptions/${currentUser.id}/Standard`
    //                     //     //             } else if (service?.Package.includes('Premium')) {
    //                     //     //                 redirectModule = `/agent/modal/agent_settings_for_subscriptions/${currentUser.id}/Premium`
    //                     //     //             } else {
    //                     //     //                 redirectModule = `/agent/modal/agent_settings_for_subscriptions/${currentUser.id}/Free`
    //                     //     //             }
    //                     //     //         }
    //                     //     //     }
    //                     //     // } else {
    //                     //     //     redirectModule = '/agent/settings/subscriptions/choose'
    //                     //     // }
    //                     // } else {
    //                     //     // if (location.pathname.includes('agent_settings_for_subscriptions') && status === 'true') {
    //                     //     //     redirectModule = location.pathname
    //                     //     // } else if (location.pathname.includes('/agent/modal/agent_advertise_create_duration/')) {
    //                     //     //     redirectModule = location.pathname
    //                     //     // } else if (location.pathname.includes('/agent/chats/')) {
    //                     //     //     redirectModule = location.pathname
    //                     //     // } else if (location.pathname.includes('/agent/modalView/viewing_modal/')) {
    //                     //     //     redirectModule = location.pathname
    //                     //     // } else if (location.pathname.includes('/agent/modal/agent_settings_for_areas_extension/')) {
    //                     //     //     redirectModule = location.pathname
    //                     //     // } else if (currentUser?.Orders && Array.isArray(currentUser.Orders) && currentUser.Orders.length > 0) {
    //                     //     //     if ((currentUser.Settings as ISettings[])?.[0]?.Areas?.length > 0) {
    //                     //     //         redirectModule = '/landlord/my_units'
    //                     //     //     } else {
    //                     //     //         if (currentUser?.Orders && Array.isArray(currentUser.Orders)) {
    //                     //     //             const latestServiceAgentSubscription = getLatestServiceAgentSubscription(currentUser?.Orders)
    //                     //     //             const service = latestServiceAgentSubscription?.Service?.[0]
    //                     //     //             if (service?.Package?.includes('Standard')) {
    //                     //     //                 redirectModule = `/agent/modal/agent_settings_for_subscriptions/${currentUser.id}/Standard`
    //                     //     //             } else if (service?.Package.includes('Premium')) {
    //                     //     //                 redirectModule = `/agent/modal/agent_settings_for_subscriptions/${currentUser.id}/Premium`
    //                     //     //             } else {
    //                     //     //                 redirectModule = `/agent/modal/agent_settings_for_subscriptions/${currentUser.id}/Free`
    //                     //     //             }
    //                     //     //         } else {
    //                     //     //             // redirectModule = '/agent/settings/subscriptions/choose'
    //                     //     //
    //                     //     //             redirectModule = '/landlord/my_units'
    //                     //     //         }
    //                     //     //     }
    //                     //     // } else {
    //                     //     //     redirectModule = '/landlord/my_units'
    //                     //     // }
    //                     //     // }
    //                     // }
    //                 }
    //             } else if ((currentUser?.tutorialCompleted === false || currentUser?.tutorialCompleted === null) && !currentUser?.emailVerified) { // userFilledFieldsLS === 'false'
    //                 if ((currentUser as User)?.ContactInfo === null) {
    //                     const updUser = store.getState().user.currentUser
    //                     const isThereCurrenDoc = updUser?.Documents !== null && Array.isArray(updUser?.Documents) && updUser?.Documents.length > 0 && updUser?.Documents[0]?.LinkBack === null && updUser?.Documents[0]?.LinkFront !== null
    //                     if (isThereCurrenDoc) {
    //                         redirectModule = '/auth/register_scan_emirates' // '/agent/modal/update_emirates_back_together/INagentMODULE/to_schoose_subscription'
    //                     } else {
    //                         redirectModule = '/auth/register_scan_emirates_front' // '/auth/register_scan_emirates'
    //                     }
    //                 }
    //                 //  else {
    //                 //     redirectModule = '/auth/write_yourself_fields'
    //                 // }
    //             }
    //
    //             //  else if (currentUser?.tutorialCompleted && !currentUser?.emailVerified && ) { // userFilledFieldsLS === 'true'
    //             //     redirectModule = '/auth/write_yourself_fields'
    //             // }
    //
    //             navigate(redirectModule)
    //             dispatch(checkStoppedCreateUnitPipeline(navigate))
    //             void dispatch(checkVerifiedUnits(navigate))
    //             // const element = document.querySelector('#root') as HTMLElement
    //             // if (element != null) {
    //             //     element.removeEventListener('touchstart', touchUpdSocketStart)
    //             //     element.addEventListener('touchstart', touchUpdSocketStart)
    //             // }
    //             if (currentUser?.id !== Number(process.env.REACT_APP_BSO_USER_ID)) {
    //                 void showNegotiateSwalsToLandlord(navigate)
    //             }
    //         }
    //     }
    //     void processUserData()
    // }, [dataLoaded])
    //
    // const landlordSocket = async () => {
    //     webSocket.onopen = () => {
    //         console.log('Connected')
    //     }
    //
    //     webSocket.onmessage = (event: MessageEvent) => {
    //         //      console.log(event, 'event')
    //         try {
    //             const data = JSON.parse(event.data)
    //
    //             if ('action' in data) {
    //                 const [collection, action] = data.action.split('.')
    //                 const entry = data?.entry
    //                 if (collection === 'open') {
    //                     if (action === 'app' && store.getState().user?.currentUser?.DeviceToken === data.deviceToken) {
    //                         if (webSocket.readyState === 3 || webSocket.readyState === 2) {
    //                             webSocket = new WebSocket('wss://bso.ae/ws')
    //                             dispatch(fetchUser(true))
    //                             dispatch(fetchDocuments(true))
    //                             dispatch(fetchViewings(true))
    //                             dispatch(fetchOffers(true, true))
    //                             dispatch(fetchUsers(true))
    //                             dispatch(fetchUnits(true))
    //                             dispatch(fetchProperties())
    //                             dispatch(fetchAdvertises(true))
    //                             void landlordSocket()
    //                         }
    //                     }
    //                 }
    //                 if (collection === 'chat-room') {
    //                     if (action === 'update' && entry.id === store.getState().chat?.chat?.id) {
    //                         // console.log(entry, 'entry')
    //                         customLog(entry, 'chat-room update')
    //                         customLog(entry?.LastMessage?.id, store.getState().chat.chat.LastMessage)
    //                         // if (entry?.LastMessage?.id !== store.getState().chat.chat.LastMessage) {
    //                         void dispatch(getChat(entry.id, 1, undefined, true))
    //                         // }
    //                     }
    //                     if (action === 'update' && _.some(store.getState().chatList.chatList, { id: entry.id })) {
    //                         void dispatch(getChatList())
    //                     }
    //                     if (action === 'create') {
    //                         void dispatch(getChatList())
    //                     }
    //                 }
    //                 if (collection === 'unit-key') {
    //                     if (action === 'create') {
    //                         if (entry.Holder.id === Number(userID)) {
    //                             const isThere = store.getState().viewings.viewings.filter((viewEl) => viewEl.id === entry?.Viewings?.[0]?.id) // ?.find((view) => view.attributes.User.data?.id === Number(userID))
    //                             if (isThere != null && isThere.length > 0) {
    //                                 dispatch(setViewings(reduceViewing(entry?.Viewings?.[0], isThere[0])))
    //                             }
    //                         }
    //                     }
    //                     if (action === 'update') {
    //                         // console.log(entry, 'entry in update unit key')
    //                         if (entry.Holder.id === Number(userID)) {
    //                             const agentViewingsIDS = store.getState().viewings.viewings.filter((el) => el?.attributes?.User?.data?.id === Number(userID))?.map((el) => el.id)
    //
    //                             const isThere = store.getState().viewings.viewings.filter(
    //                                 view => view.id === entry?.Viewings?.filter((el: any) => agentViewingsIDS.includes(el.id))[0].id
    //                             )
    //                             //     const isThere = store.getState().viewings.viewings.filter((viewEl) => viewEl.id === entry?.Viewings?.[0]?.id) // ?.find((view) => view.attributes.User.data?.id === Number(userID))
    //                             if (isThere != null && isThere.length > 0) {
    //                                 dispatch(setViewings(reduceViewingAfterUpdateKey(entry, isThere[0])))
    //                             }
    //                         }
    //                     }
    //                 }
    //
    //                 if (collection === 'user') {
    //                     if (action === 'create') {
    //                         dispatch(fetchSocketUser(entry?.id ?? -1))
    //                     }
    //                     if (action === 'update') {
    //                         if (entry.id === Number(userID)) {
    //                             //      console.log(entry, '============> entry <=================')
    //                             dispatch(fetchUser(true))
    //                             const actualUser = store.getState().user?.currentUser
    //                             const updateUser = { ...actualUser, ...entry } as User
    //                             // dispatch(userUpdate(updateUser))
    //                             if (updateUser.emailVerified) {
    //                                 setIsUserVerified(true)
    //                             } else {
    //                                 setIsUserVerified(false)
    //                             }
    //                             const docs = updateUser?.Documents
    //                             dispatch(setStatuses(getAgentStatuses(docs)))
    //                         } else {
    //                             dispatch(fetchSocketUser(entry?.id ?? -1, true))
    //                             if (entry.id === store.getState().chat?.chat?.User?.id) {
    //                                 if (store.getState().chat?.chat?.id != null) {
    //                                     void dispatch(getChat(Number(store.getState().chat.chat.id), 1, undefined, true))
    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }
    //                 if (collection === 'document') {
    //                     console.log(action, entry)
    //                     if (action === 'create') {
    //                         const keys = ['Offer', 'Unit', 'Advertise']
    //                         for (const key of keys) {
    //                             if (key in entry && entry[key] != null) {
    //                                 // TODO add check that entity belongs to user and copy to AgentModule
    //                                 void dispatch(fetchDocuments(true))
    //                                 break
    //                             }
    //                         }
    //                     }
    //                     if (action === 'update') {
    //                         //        console.log(entry, '============> entry <=================')
    //                         //  console.log(userID, 'userID<========== =========>entry.User.id', entry.User.id)
    //                         if (entry?.User?.id === Number(userID)) {
    //                             //    const isMy = store.getState().documents.documents.filter((docEl) => docEl.id === entry.id)
    //                             //    if (isMy !== null && isMy.length > 0) {
    //                             void swalSuccess(`Your ${getCorrectName(entry?.Type[0].__component)} is ${entry?.Statuses[0]}`).then(async (result: any) => {
    //                                 if (result.isConfirmed) {
    //                                     dispatch(documentAddUpdate(entry)) // , isMy[0]
    //                                     dispatch(setStatuses(getAgentStatuses(store.getState().documents.documents)))
    //                                 }
    //                             })
    //                         }
    //                     }
    //                 }
    //                 if (collection === 'viewing') {
    //                     dispatch(fetchViewings(true))
    //                     // if (action === 'create') {
    //                     //     if (entry?.User.id !== Number(userID)) {
    //                     //         dispatch(addViewing(reduceViewingAfterCreate(entry)))
    //                     //     }
    //                     // }
    //                     // if (action === 'update') {
    //                     //     const isThere = store.getState().viewings.viewings.filter((viewEl) => {
    //                     //         return viewEl.id === entry.id
    //                     //     })
    //                     //     dispatch(setViewings(reduceViewing(entry, isThere[0])))
    //                     // }
    //                 }
    //                 if (collection === 'offer') {
    //                     const processOffer = (): void => {
    //                         if (currentUser?.id === Number(process.env.REACT_APP_BSO_USER_ID)) {
    //                             return
    //                         }
    //                         const storedOffer = store.getState()?.offers?.offers?.find((offerItem) => offerItem.id === entry.id)
    //                         const offer = removeDataAttributes(_.cloneDeep(storedOffer ?? entry))
    //                         void processOfferSwalForLandlord(offer, dispatch, navigate)
    //                     }
    //                     if ([Number(process.env.REACT_APP_BSO_USER_ID), entry.AgentToLandlordID].includes(Number(userID))) {
    //                         if (entry.Type != null && entry.Unit != null && entry.User != null) {
    //                             const { isSuccessful } = dispatch(processSocketOffer(entry))
    //                             if (isSuccessful !== true) {
    //                                 void dispatch(fetchOffers(true, true))
    //                                     .then(processOffer)
    //                             } else {
    //                                 processOffer()
    //                             }
    //                         } else {
    //                             dispatch(fetchOffers(true, true))
    //                                 .then(processOffer)
    //                         }
    //                     }
    //                 }
    //
    //                 if (collection === 'unit') {
    //                     if (action === 'update') {
    //                         /* if (_.some(store.getState().units.catalog, { id: entry.id })) {
    //                             dispatch(fetchSingleUnit(entry.id))
    //                                 .then((result: any) => {
    //                                     const unitsCopy = _.cloneDeep(store.getState().units.catalog)
    //                                     const index = _.findIndex(unitsCopy, { id: entry.id })
    //                                     unitsCopy[index] = { id: entry.id, attributes: result }
    //                                     dispatch(unitsSetCatalog(unitsCopy))
    //                                 })
    //                                 .catch((err: any) => {
    //                                     customLog(err)
    //                                 })
    //                         } */
    //                         if (_.some((store.getState().user.currentUser as any).Units, { id: entry.id })) {
    //                             /* dispatch(fetchSingleUnit(entry.id))
    //                                 .then((result: any) => {
    //                                     const unitsCopy = _.cloneDeep((store.getState().user.currentUser as any).Units)
    //                                     const index = _.findIndex(unitsCopy, { id: entry.id })
    //                                     unitsCopy[index] = { id: entry.id, ...result }
    //                                     dispatch(setCurrentUserUnits(unitsCopy))
    //                                 })
    //                                 .catch((err: any) => {
    //                                     customLog(err)
    //                                 }) */
    //                             dispatch(fetchUser(true))
    //                         }
    //                         if (_.some(store.getState().chat.searchedInvites, (invite) => invite?.Unit?.attributes?.id === entry.id)) {
    //                             const invitesCopy = _.cloneDeep(store.getState().chat.searchedInvites)
    //                             const index = _.findIndex(invitesCopy, (invite) => invite?.Unit?.attributes?.id === entry.id)
    //                             dispatch(fetchSingleUnit(entry.id))
    //                                 .then((result: any) => {
    //                                     if (invitesCopy?.[index]?.Unit?.attributes != null) {
    //                                         (invitesCopy[index].Unit as any).attributes = result
    //                                     }
    //                                 })
    //                                 .catch((err: any) => {
    //                                     customLog(err)
    //                                 })
    //                         }
    //                     } else if (action === 'create') {
    //                         if (entry.User?.id === store.getState().user.currentUser?.id) {
    //                             const unitsCopy = _.cloneDeep((store.getState().user.currentUser as any).Units)
    //                             unitsCopy.push(entry)
    //                             dispatch(setCurrentUserUnits(unitsCopy))
    //                             dispatch(fetchUnits(true))
    //                         }
    //                     } else if (action === 'delete') {
    //                         console.log(entry)
    //                         if (_.some((store.getState().user.currentUser as any).Units, { id: entry.id })) {
    //                             dispatch(setCurrentUserUnits((store.getState().user.currentUser as any).Units.filter((unit: any) => unit.id !== entry.id)))
    //                         }
    //                     }
    //                 }
    //                 if (collection === 'unit-advertise') {
    //                     if (action === 'update') {
    //                         const advertise = _.find(store.getState().advertises.advertises, advertise => advertise.id === entry.id)
    //                         if (advertise != null && (advertise?.attributes?.User.data?.id === Number(userID) || userID === process.env.REACT_APP_BSO_USER_ID)) {
    //                             dispatch(setAdvertises(reduceAdvertice(entry, advertise)))
    //                         }
    //                     }
    //                     if (action === 'create') {
    //                         const advertise = { id: entry.id, attributes: { ...entry } }
    //                         const unit = _.find(store.getState().units.units, (unit) => unit.id === entry.Unit.id)
    //                         const user = _.find(store.getState().user.allUsers, (user) => user.id === entry.User.id)
    //                         advertise.attributes.Unit = { id: unit?.id, data: unit }
    //                         advertise.attributes.User = { id: user?.id, data: user }
    //                         dispatch(addAdvertise(advertise))
    //                     }
    //                 }
    //             }
    //         } catch (err) {
    //             console.log(err)
    //         }
    //     }
    //
    //     webSocket.onclose = () => {
    //         dispatch(fetchUser(true))
    //         dispatch(fetchDocuments(true))
    //         dispatch(fetchViewings(true))
    //         dispatch(fetchOffers(true, true))
    //         dispatch(fetchUsers(true))
    //         dispatch(fetchUnits(true))
    //         dispatch(fetchProperties())
    //         dispatch(fetchAdvertises(true))
    //         void landlordSocket()
    //     }
    // }

    // function touchUpdSocketStart (): void {
    //     if (webSocket.readyState === 3 || webSocket.readyState === 2) {
    //         webSocket = new WebSocket('wss://bso.ae/ws')
    //         dispatch(fetchUser(true))
    //         dispatch(fetchDocuments(true))
    //         dispatch(fetchViewings(true))
    //         dispatch(fetchOffers(true, true))
    //         dispatch(fetchUsers(true))
    //         dispatch(fetchUnits(true))
    //         dispatch(fetchProperties())
    //         dispatch(fetchAdvertises(true))
    //         void landlordSocket()
    //     }
    // }

    useEffect(() => {
        const func = () => {
            if (wb.readyState === 3) {
                webSocket = new WebSocket('wss://bso.ae/ws')
                setWb(webSocket)
                void landlordSocket(webSocket, dispatch)
            }
        }
        window.myController.abort()
        window.myController = new AbortController()
        window.addEventListener('open-app', func, { signal: window.myController.signal })
    }, [wb])

    if (!dataLoaded) {
        return <Loader />
    }

    return (
        <SearchProvider>
            {orientation === 'landscape'
                ? <LandscapePlaceholder />
                : <>
                    {
                        // TODO implement
                        isUserVerified || currentUser?.emailVerified === true
                            ? null
                            : [
                                // CREATE FIRST UNIT
                                '/landlord/modal/landlord_create_unit',
                                '/landlord/modal/landlord_create_unit_owners_passport',
                                '/landlord/modal/landlord_create_unit_correct_owner_info',
                                '/landlord/modal/landlord_create_unit_poa',
                                '/landlord/modal/landlord_create_unit_info',
                                '/landlord/modal/landlord_create_main_info_first',
                                '/landlord/modal/landlord_create_main_info_second',
                                '/landlord/modal/landlord_create_unit_additional_info',
                                '/landlord/modal/landlord_create_unit_additional_info_outdoor',
                                '/landlord/modal/landlord_create_unit_additional_info_files',

                                '/landlord/modal/update_passport_together',
                                '/landlord/modal/update_emirates_back_together',
                                '/landlord/modal/update_emirates_front_together',
                                '/auth/register_scan_emirates'
                            ].some((path) => location.pathname.includes(path)) // 'landlord/modal/update_passport_together') || location.pathname.includes('/landlord/modal/update_emirates_back_together') || location.pathname.includes('/landlord/modal/update_emirates_front_together') || location.pathname.includes('/auth/register_scan_emirates')
                                ? null
                                : <AlertsComponentVerify />
                    }
                    <Outlet />
                </>
            }
        </SearchProvider>
    )
}
