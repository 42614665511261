import { type AppDispatch, type RootState } from '../../../../store'
import { dataFetchingFinish, dataFetchingStart } from '../../../../slices/modalSlice'
import { landlordAddUnitClearStorage, landlordAddUnitReadFromStorage } from '../utils/landlordAddUnitHandleStorage'
import { ACCESS_KEY, urlAPI } from '../../../../../urls'
import { nanoid } from 'nanoid'
import { createSingleLandlord, fetchUnits, singleUnitFetchingSuccess } from '../../../unitsActions'
import { fetchUser } from '../../../userActions'
import dayjs from 'dayjs'
import { type User } from '../../../../slices/userSlice'
import Swal from 'sweetalert2'
import { swalOptions } from '../../../../../functions/swalOptions'
import { fetchDocuments } from '../../../documentActions'
import {getQueryUnits} from "../../../../../functions/shared/api/queries/getQueryUnits";

export const addUnit = (unitID?: number | null, calledFrom?: 'title_deed_step' | undefined) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    dispatch(dataFetchingStart())
    const storedData = landlordAddUnitReadFromStorage()
    const currentUser = getState().user.currentUser as User
    const documents = getState().documents.documents
    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
    try {
        let data: Record<string, unknown> = {}
        if (unitID == null) {
            const propertyId = storedData.unit_info?.Property?.id
            const property = getState().property.properties.find(propertyItem => propertyItem?.id === propertyId)
            data.RefID = property != null && storedData?.title_deed?.response?.document?.property?.number != null
                ? `${String(storedData.title_deed.response.document.property.number)}-${String(property?.attributes?.Name)}-${nanoid()}`
                : `${String(storedData?.unit_info?.Number)}-${nanoid()}`

            data.Property = propertyId

            data.User = getState().user.currentUser?.id

            if (storedData.title_deed?.id != null) {
                data.Documents = [storedData.title_deed.id]
            }

            data.Number = storedData?.unit_info?.Number
            data.Statuses = ['Vacant']

            data = {
                ...data,
                ...(storedData.main_unit_info_first ?? {}),
                ...(storedData.main_unit_info_second ?? {}),
                ...(storedData.additional_unit_info ?? {}),
                ...(storedData.additional_unit_info_outdoor ?? {}),
                ...(storedData.additional_unit_info_files ?? {}),
                ...(storedData.unit_marketing_info ?? {}),
                ...(storedData.unit_marketing_special_requests ?? {})
            }

            if (storedData?.owners_passport != null) {
                data.Landlords = [
                    {
                        Landlord: storedData.owners_passport.landlordID,
                        Representatives: [
                            storedData.owners_passport.representativeID
                        ],
                        PowerOfAttorney: storedData.poa.id
                    }
                ]
            } else {
                const passport = documents.find((doc) => (
                    doc.attributes.User?.data?.id === currentUser.id &&
                    doc.attributes?.Type?.[0]?.__component === 'documents.passport' &&
                    dayjs().isBefore(dayjs(doc.attributes.ExpiryDate))
                ))
                if (passport != null) {
                    let landlordID: number | undefined
                    if ((passport.attributes as any)?.Landlord?.data?.id != null) {
                        landlordID = (passport.attributes as any).Landlord.data.id
                    } else {
                        landlordID = await dispatch(createSingleLandlord({
                            FirstName: currentUser?.ContactInfo?.FirstName,
                            FamilyName: currentUser?.ContactInfo?.FamilyName,
                            Country: currentUser?.ContactInfo?.Country,
                            DateOfBirth: currentUser?.ContactInfo?.DateOfBirth,
                            Gender: currentUser?.ContactInfo?.Gender,
                            Passport: passport.id,
                            DocumentNumber: passport.attributes?.Type?.[0]?.Number as string
                        }))
                    }
                    data.Landlords = [
                        {
                            Landlord: landlordID
                        }
                    ]
                }
                // Case where user has no valid passport
                // const passport = documents.find((doc) => (
                //     doc.attributes.User?.data?.id === currentUser?.id &&
                //     doc.attributes?.Type?.[0]?.__component === 'documents.passport' &&
                //     dayjs().isBefore(dayjs(doc.attributes.ExpiryDate))
                // ))
                // if (passport != null) {
                //     let landlordID: number | undefined
                //     if ((passport.attributes as any)?.Landlord?.data?.id != null) {
                //         landlordID = (passport.attributes as any)?.Landlord?.data?.id
                //     } else {
                //         landlordID = await dispatch(createSingleLandlord({
                //             FirstName: currentUser?.ContactInfo?.FirstName,
                //             FamilyName: currentUser?.ContactInfo?.FamilyName,
                //             Country: currentUser?.ContactInfo?.Country,
                //             DateOfBirth: currentUser?.ContactInfo?.DateOfBirth,
                //             Gender: currentUser?.ContactInfo?.Gender,
                //             Passport: passport?.id,
                //             DocumentNumber: passport.attributes?.Type?.[0]?.Number as string
                //         }))
                //     }
                //     data.Landlords = [
                //         {
                //             Landlord: landlordID
                //         }
                //     ]
                // }
            }
        } else {
            const currentUnit = getState().units.units.find(unit => unit.id === unitID)
            if (storedData.title_deed?.id != null) {
                data.Documents = [storedData.title_deed.id]
            }

            data.Property = storedData.unit_info?.Property?.id

            data.Number = storedData?.unit_info?.Number ?? currentUnit?.attributes?.Number

            data = {
                ...data,
                ...(storedData.main_unit_info_first ?? {}),
                ...(storedData.main_unit_info_second ?? {}),
                ...(storedData.additional_unit_info ?? {}),
                ...(storedData.additional_unit_info_outdoor ?? {}),
                ...(storedData.additional_unit_info_files ?? {}),
                ...(storedData.unit_marketing_special_requests ?? {}),
                Marketing: {
                    ...(currentUnit?.attributes?.Marketing ?? {}),
                    ...(storedData.unit_marketing_info?.Marketing ?? {})
                }
            }

            if (storedData?.owners_passport != null) {
                data.Landlords = [
                    {
                        Landlord: storedData?.owners_passport?.landlordID ?? currentUnit?.attributes?.Landlords?.[0]?.Landlord?.data?.id,
                        Representatives: [
                            storedData?.owners_passport?.representativeID ?? currentUnit?.attributes?.Landlords?.[0]?.Representatives?.data?.[0]?.id
                        ],
                        PowerOfAttorney: storedData?.poa?.id ?? currentUnit?.attributes?.Landlords?.[0]?.PowerOfAttorney?.data?.id
                    }
                ]
            } else {
                if (currentUnit?.attributes?.Tasks?.data?.some(task => task?.attributes?.Type === 'Document Verification' && ['To do', 'In-process'].includes(task?.attributes?.Statuses?.[0])) !== true) {
                    const passport = documents.find((doc) => (
                        doc.attributes.User?.data?.id === currentUser.id &&
                        doc.attributes?.Type?.[0]?.__component === 'documents.passport' &&
                        dayjs().isBefore(dayjs(doc.attributes.ExpiryDate))
                    ))
                    if (passport != null) {
                        let landlordID: number | undefined
                        if ((passport.attributes as any)?.Landlord?.data?.id != null) {
                            landlordID = (passport.attributes as any).Landlord.data.id
                        } else {
                            landlordID = await dispatch(createSingleLandlord({
                                FirstName: currentUser?.ContactInfo?.FirstName,
                                FamilyName: currentUser?.ContactInfo?.FamilyName,
                                Country: currentUser?.ContactInfo?.Country,
                                DateOfBirth: currentUser?.ContactInfo?.DateOfBirth,
                                Gender: currentUser?.ContactInfo?.Gender,
                                Passport: passport.id,
                                DocumentNumber: passport.attributes?.Type?.[0]?.Number as string
                            }))
                        }
                        data.Landlords = [
                            {
                                Landlord: landlordID
                            }
                        ]
                    }
                }
            }
        }

        const bodyObject = {
            method: unitID != null ? 'PUT' : 'POST',
            collection: 'units',
            body: data,
            id: unitID ?? undefined,
            query: getQueryUnits()
        }

        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify(bodyObject),
            headers: {
                'Content-Type': 'application/json',
                'BSO-Hash': '02ns30nsBSO!',
                Authorization: `Bearer ${jwt}`
            }
        })

        const responseJSON = await response?.json() as unknown as any
        responseJSON.isSuccessful = responseJSON?.response?.error == null
        if (responseJSON.isSuccessful === true && calledFrom !== 'title_deed_step') {
            responseJSON.textNotification = ''
            responseJSON.textNavigate = '/landlord/my_units'
            await Swal.fire({
                ...swalOptions('success'),
                title: `Unit has been successfully ${unitID != null ? 'edited!' : 'created!'}`,
                text: 'Do you want to continue by ordering a service',
                showConfirmButton: true,
                confirmButtonText: 'Continue',
                showCancelButton: true,
                cancelButtonText: unitID != null ? 'Skip' : 'Order later',
                preConfirm: () => {
                    // responseJSON.textNavigate = `/landlord/modal/landlord_create_unit_marketing/${responseJSON?.response?.data?.id as number}/-`
                    responseJSON.textNavigate = `/landlord/services/${responseJSON?.response?.data?.id as number}/`
                }
            })
            //   console.log(responseJSON)
            dispatch(dataFetchingFinish())
            const { isSuccessful } = dispatch(singleUnitFetchingSuccess(responseJSON))
            if (!isSuccessful) {
                void dispatch(fetchUser(true))
                void dispatch(fetchUnits(true))
            }

            void dispatch(fetchDocuments(true))

            landlordAddUnitClearStorage()
        }
        return responseJSON
    } catch (err) {
        console.log('Error while adding unit', err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false
    }
}
