import { type AppDispatch, type RootState } from '../../../../store'
import { dataFetchingFinish, dataFetchingStart } from '../../../../slices/modalSlice'
import { addProperty } from '../../../propertyActions'
import { landlordAddUnitReadFromStorage, landlordAddUnitWriteToStorage } from '../utils/landlordAddUnitHandleStorage'
import { type NavigateFunction } from 'react-router-dom'
import nanToNull from '../../../../../functions/adapters/universal/nanToNull'

export const addUnitInfo = (unitID?: number) => (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<any> => {
    dispatch(dataFetchingStart())
    // const storedData = landlordAddUnitReadFromStorage()
    // const currentUser = getState().user.currentUser as User
    try {
        console.log(data)
        const propertyResponse = await dispatch(addProperty({
            ...data.Property,
            Type: 'Community'
        }, true))
        if (propertyResponse.response.data.id != null) {
            data.Property.id = propertyResponse.response.data.id
            landlordAddUnitWriteToStorage('unit_info', data)
            return {
                textNotification: '',
                textNavigate: `/landlord/modal/landlord_create_main_info_first/${nanToNull(unitID) ?? '-'}/-`,
                isSuccessful: true
            }
        }
    } catch (err) {
        console.log('Error while adding unit info', err)
    }
    dispatch(dataFetchingFinish())
    return {
        isSuccessful: false
    }
}

export const skipAddUnitInfo = (navigate: NavigateFunction, unitID?: number) => async () => {
    navigate(`/landlord/modal/landlord_create_main_info_first/${nanToNull(unitID) ?? '-'}/-`)
}

export const goBackAddUnitInfo = (navigate: NavigateFunction, unitID?: number) => () => {
    const storedData = landlordAddUnitReadFromStorage()
    if (storedData.owners_passport != null) {
        navigate(`/landlord/modal/landlord_create_unit_poa/${nanToNull(unitID) ?? '-'}/-`)
    } else {
        navigate(`/landlord/modal/landlord_create_unit/${nanToNull(unitID) ?? '-'}/-`)
    }
}
