import {useNavigate} from "react-router";
import {useEffect} from "react";
import {LANDLORD_AWAIT_FIRST_UNIT_CREATION_KEY} from "../../urls";
import {store} from "../../store/store";

export default function useLandlordLoadProcessData (dataLoaded: boolean): void {
    const navigate = useNavigate()
    useEffect(() => {
        const currentUser = store.getState().user.currentUser
        if (dataLoaded) {
            let redirectModule: string | null = null
            const createFirstUnitIsAwaited = localStorage.getItem(LANDLORD_AWAIT_FIRST_UNIT_CREATION_KEY) === 'true'
            if (currentUser?.tutorialCompleted === true) {
                if (createFirstUnitIsAwaited) {
                    localStorage.setItem(LANDLORD_AWAIT_FIRST_UNIT_CREATION_KEY, 'false')
                    redirectModule = '/landlord/modal/landlord_create_unit/-/-'
                }
            } else {
                redirectModule = '/landlord/modal/update_emirates_front_together/INagentMODULE/first'
            }
            if (redirectModule != null) {
                navigate(redirectModule)
            }
        }
    }, [dataLoaded, navigate])
}